@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  margin-top: 50px;
}

.slots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.linha {
  display: flex;
}

.linha span {
  font-size: 36px;
  padding: 10px;
}
